@import url("https://fonts.googleapis.com/css2?family=Arya:wght@400;700&family=Lato:wght@100;300;400;700&display=swap");

#container {
    margin: 80px 180px;
}

header h1 {
    font-family: "Arya", sans-serif;
    font-size: 2.5em;
    letter-spacing: 4px;
    margin-bottom: 5px;
}
header ul {
    list-style-type: none;
    position: absolute;
    right: 0;
    top: 0;
}
header {
    position: relative;
    border-bottom: 1px solid;
    font-family: "Arya", sans-serif;
}

header li {
    float: left;
    padding: 35px;
    padding-top: 16px;
    padding-right: 0px;
    letter-spacing: 3px;
}

header a {
    text-decoration: none;
    color: black;
}

#projects {
    margin-top: 75px;
    margin-bottom: 75px;
}

.boxes {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12.5px;
    margin-right: -12.5px;
}

.box {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 12.5px;
}

.box img {
    width: 100%;
}

#contact-me {
    background-color: #cad9dc;
    font-family: "Lato", sans-serif;
    text-align: center;
    padding: 70px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 120px 0px;
}

#contact-page {
    background-color: #cad9dc;
    font-family: "Lato", sans-serif;
    text-align: center;
    padding: 70px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 120px 0px;
    font-size: 15px;
}

.work-bold {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.095em;
}

footer {
    display: flex;
    margin-top: 150px;
    font-family: Lato;
    font-weight: 100;
    justify-content: space-around;
    flex-direction: row;
    font-size: 10px;
    text-transform: uppercase;
    padding-top: 25px;
    border-top: 1px solid black;
}

#icons {
    flex-basis: 33.33%;
    text-align: center;
}

#footer-rights {
    flex-basis: 33.33%;
    text-align: right;
}

#footer-name {
    flex-basis: 33.33%;
}

#icons a {
    margin: 10px;
}

#heading {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
}

#intro p {
    font-family: "Lato", sans-serif;
    font-family: Lato;
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.095em;
}

#intro {
    width: 50%;
}

#heading-top {
    font-family: "Lato", sans-serif;
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 70px;
}

#image-permission {
    font-family: "Lato", sans-serif;
    font-family: Lato;
    font-style: normal;
    font-size: 8px;
    line-height: 14px;
    letter-spacing: 0.095em;
    font-weight: 200;
}

#bvlgari-campaign {
    font-family: "Lato", sans-serif;
    font-family: Lato;
    font-style: normal;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.095em;
}

#about-me {
    font-family: "Lato", sans-serif;
    font-family: Lato;
    font-style: normal;
    font-size: 12px;
    line-height: 15x;
    letter-spacing: 0.095em;
}

#services {
    font-size: 16px;
    font-family: "Lato", sans-serif;
    font-family: Lato;
    font-weight: bold;
}

#services-category {
    font-family: "Lato", sans-serif;
    font-family: Lato;
    font-style: normal;
    font-size: 12px;
    line-height: 15x;
    letter-spacing: 0.095em;
}

#wishing-well {
    padding: 40px;
}

.gif1 {
    background-color: #fcf3f3;
}

.gif2 {
    background-color: #eff6f6;
    margin-top: 25px;
}

#wishing-well div {
    padding: 80px;
}

#contact-page a {
    text-decoration: none;
    color: black;
}

#contact-page a:hover {
    text-decoration: underline;
}
